(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/sport/ice-hockey.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/sport/ice-hockey.js');
"use strict";


const newEnetpulseWidgets = svs.core.detect.feature('ft-enetpulse-new');
const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    EnetPulseViewType,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  commonConfig
} = svs.components.sportinfo.configs.sportConfigs;
const {
  ContentProvider,
  EnetpulseWidgets
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems: enetpulseMenuItems,
  content: enetpulseContent
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: EnetPulseViewType.HeadToHead,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.HeadToHead,
    viewProps: {
      options: {
        wv: 4,
        lng: 'sv',
        el_limit: 5,
        sh_h2h_s_f: 'yes',
        h2h_sh_m: 'yes'
      }
    }
  }, {
    type: EnetPulseViewType.WinProbability,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.WinProbability,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: EnetPulseViewType.MatchStatistics,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.MatchStatistics,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]);
}).addTab(SportinfoPathNames.Events, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: EnetPulseViewType.MatchIncidents,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.MatchIncidents,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: EnetPulseViewType.TournamentStandings,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.TournamentStandings,
    viewProps: {
      options: {
        lng: 'sv',
        sh_ha_d: 'tabs'
      }
    }
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.sportTypeOverrides', {
  iceHockeyConfig: newEnetpulseWidgets ? {
    menuItems: enetpulseMenuItems,
    content: enetpulseContent,
    config: 'ice-hockey' 
  } : commonConfig
});

 })(window);